@import '../../styles/media-queries';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-sizing: border-box;

  &__inner {
    background: var(--bg-color-primary-white);
    border-radius: 8rem;
    clip-path: inset(0 0 0 0 round 8rem 8rem 0 0);

    margin: 0 -2.1rem;
    padding: 0 2.1rem;

    @include for-screen-up(640) {
      border-radius: 13.1rem;
      clip-path: inset(0 0 0 0 round 13.1rem 13.1rem 0 0);

      margin: 0 -3rem;
      padding: 0 3rem;
    }

    @include for-screen-up(1024) {
      clip-path: inset(0 0 0 0 round 14rem 14rem 0 0);

      border-radius: 14rem;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;

    padding: 8.1rem 1.8rem 0;

    @include for-screen-up(640) {
      padding: 8.1rem 6rem 0;
    }

    @include for-screen-up(960) {
      padding: 12.1rem 2rem 0;
    }

    @include for-screen-up(1024) {
      padding: 8.2rem 2rem 0;
    }

    @include for-screen-up(1366) {
      padding: 8.2rem 2rem 0;
    }

    @include for-screen-up(1680) {
      padding: 13rem 2rem 0;
    }

    @include for-screen-up(1920) {
      max-width: 160rem;
      padding: 13rem 0 0;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 auto 1.1rem;
    font-size: 3.5rem;
    white-space: pre-wrap;

    font-weight: normal;
    line-height: 1.36;
    text-align: center;

    @include for-screen-up(640) {
      margin-bottom: 3.3rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 4rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 3.1rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 4.2rem;
      font-size: 6.1rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 5.2rem;
      font-size: 7.5rem;
    }
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
    list-style: none;

    @include for-screen-up(960) {
      flex-direction: row;
    }

    @include for-screen-up(1680) {
      gap: 2.8rem;
    }
  }

  &__list-item {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    opacity: 0;
    color: var(--color-secondary-93);
    transform: scale(0.5);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &:nth-child(1) {
      transition-delay: 0.3s;
    }

    &:nth-child(2) {
      transition-delay: 0.6s;
    }

    #{$section}_animation-start & {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__list-card {
    border-radius: 3rem;
    padding: 4.3rem 3.9rem 3.5rem 3.2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;

    align-items: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;

    @include for-screen-up(640) {
      border-radius: 4rem;

      padding: 6.4rem 4.7rem 5.8rem 4.9rem;
    }

    @include for-screen-up(960) {
      padding: 6.4rem 2.1rem 5.8rem 5rem;
    }

    @include for-screen-up(1366) {
      border-radius: 4rem;

      padding: 6.4rem 9rem 5.8rem;
    }

    @include for-screen-up(1680) {
      border-radius: 5rem;

      padding: 7.8rem 4.8rem 8rem 11rem;
    }

    #{$section}__list-item:nth-child(1) & {
      background: #ecf7ff;
    }

    #{$section}__list-item:nth-child(2) & {
      background: #f6f6f6;
    }
  }

  &__list-card-heading {
    margin-bottom: 0.5rem;
    font-size: 2.5rem;
    line-height: 1.4;

    @include for-screen-up(640) {
      margin-bottom: 0.7rem;
      font-size: 3.6rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 0.4rem;
      font-size: 4.5rem;
    }
  }

  &__list-card-summary {
    margin-bottom: 1rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    line-height: 1.7;

    @include for-screen-up(640) {
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 2.1rem;
      font-size: 2rem;
    }
  }

  &__list-card-fig {
    height: 7.8rem;
    margin-bottom: 1.6rem;

    @include for-screen-up(640) {
      margin-bottom: 2.1rem;
      height: 11rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 2.6rem;
      height: auto;
    }

    svg {
      display: block;
      transform: scale(0.58);
      transform-origin: 0 0;

      @include for-screen-up(640) {
        transform: scale(0.81);
      }

      @include for-screen-up(1680) {
        transform: none;
      }
    }
  }

  &__list-card-list {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
    margin: 0 0 3rem;
    padding: 0;
    list-style: none;

    @include for-screen-up(640) {
      margin-bottom: 4.4rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 4.2rem;
    }
  }

  &__list-card-list-item {
    margin: 0;
    padding: 0 0 0 3.1rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.4rem;
    line-height: 1.8;
    position: relative;

    @include for-screen-up(640) {
      font-size: 1.4rem;
      line-height: 2.1;
    }

    @include for-screen-up(1024) {
      padding: 0 0 0 2.7rem;
      line-height: 1.9;
    }

    @include for-screen-up(1680) {
      font-size: 1.8rem;
      line-height: 1.8;
      padding: 0 0 0 3.1rem;
    }

    &:before {
      content: '';
      background: url('/images/list-points/type1.svg') no-repeat;
      transform: scale(0.9);
      width: 2.1rem;
      height: 2.1rem;
      position: absolute;
      top: 0.2rem;
      left: 0;

      @include for-screen-up(640) {
        top: 0.3rem;
      }

      @include for-screen-up(1024) {
        top: 0.3rem;
      }

      @include for-screen-up(1680) {
        transform: scale(1);
        top: 0.5rem;
      }
    }

    &:nth-child(3n - 1) {
      &:before {
        background-image: url('/images/list-points/type2.svg');
      }
    }

    &:nth-child(3n) {
      &:before {
        background-image: url('/images/list-points/type3.svg');
      }
    }
  }

  &__list-card-button {
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }
}
