@import '../../styles/media-queries';

.sticker {
  background: #ffe141;
  border-radius: 6.1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 5.3rem;
  padding: 0 2rem;
  color: var(--text-color-primary-black);
  font-size: 2.5rem;
  line-height: 1.36;
  gap: 0.7rem;
  box-sizing: border-box;

  @include for-screen-up(640) {
    border-radius: 7.2rem;
    height: 6.3rem;

    font-size: 3rem;
    padding: 0 2.4rem;
  }

  @include for-screen-up(960) {
    border-radius: 6.6rem;
    padding: 0 2.1rem;
    height: 5.7rem;
    font-size: 2.8rem;
  }

  @include for-screen-up(1024) {
    border-radius: 6.9rem;
    height: 6rem;
    padding: 0 2.2rem;
    font-size: 2.9rem;
  }

  @include for-screen-up(1366) {
    border-radius: 7.4rem;
    height: 6.5rem;
    padding: 0 2.4rem;
    font-size: 3.1rem;
  }

  @include for-screen-up(1680) {
    border-radius: 9rem;
    height: 7.8rem;
    padding: 0 2.9rem;
    font-size: 3.8rem;
  }

  &__after {
    margin-right: -0.7rem;

    @include for-screen-up(640) {
    }

    @include for-screen-up(960) {
      margin-right: -1rem;
    }

    svg {
      display: block;
    }
  }
}
