@import '../../styles/media-queries';
@import '../../styles/functions';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__inner {
    @include for-screen-up(640) {
      padding: 0 6rem;
    }

    @include for-screen-up(960) {
      display: flex;
      justify-content: center;
    }
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  &__content {
    display: flex;
    background: var(--color-secondary-104);
    border-radius: 3.5rem;
    width: 100%;
    margin: auto;
    flex-direction: column;
    box-sizing: border-box;
    padding: 4.5rem 1.8rem 3rem;
    align-items: center;
    justify-content: center;
    position: relative;

    @include for-screen-up(960) {
      width: 40rem;
      padding: 8.1rem 5.2rem 7.8rem;
    }

    @include for-screen-up(1280) {
      width: 50.9rem;
      padding: 10rem 6.7rem;
    }

    @include for-screen-up(1680) {
      width: 54.7rem;
      padding: 10.8rem 6.9rem 11.2rem;
    }
  }

  &__heading {
    margin-bottom: 1rem;
    color: var(--color-secondary-105);
    font-size: 4rem;
    line-height: 1.36;
    text-align: center;
    max-width: 34rem;

    @include for-screen-up(960) {
      margin-bottom: 0.3rem;
      max-width: none;
    }

    @include for-screen-up(1280) {
      font-size: 5rem;
    }

    @include for-screen-up(1680) {
      font-size: 5.5rem;
    }
  }

  &__fig {
    display: none;

    @include for-screen-up(960) {
      display: block;
      margin-bottom: 1.2rem;
    }

    @include for-screen-up(1280) {
      margin-bottom: 2rem;
    }
  }

  &__img {
    display: block;

    @include for-screen-up(960) {
      width: 11.7rem;
    }

    @include for-screen-up(1280) {
      width: 14.8rem;
    }

    @include for-screen-up(1680) {
      width: 15.9rem;
    }
  }

  &__summary {
    margin-bottom: 3.5rem;
    color: var(--color-secondary-105);
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    line-height: 1.7;
    text-align: center;
    max-width: 34rem;

    @include for-screen-up(960) {
      margin-bottom: 0;
      font-size: 1.5rem;
      max-width: none;
    }

    @include for-screen-up(1280) {
      font-size: 1.9rem;
    }

    @include for-screen-up(1680) {
      font-size: 2rem;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1.7rem;

    @include for-screen-up(960) {
      display: block;
    }
  }

  &__list-item {
    background: #bdefd0;
    border-radius: 2.8rem;
    display: flex;
    max-width: 16.1rem;
    height: 18rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex: 0 1 calc(50% - 0.85rem);
    gap: 1.7rem;
    opacity: 0;

    transition: transform 0.3s ease, opacity 0.5s ease;

    @include for-screen-up(960) {
      background: var(--color-secondary-104);
      width: 18.3rem;
      height: 18.3rem;
      max-width: none;
      position: absolute;
      z-index: -1;
    }

    @include for-screen-up(1280) {
      width: 23.2rem;
      height: 23.2rem;
    }

    @include for-screen-up(1680) {
      width: 25rem;
      height: 25rem;
    }

    &:nth-child(1) {
      transform: translateX(200%);
      transition-delay: 0.2s;

      @include for-screen-up(960) {
        left: -25.4rem;
        top: 6.2rem;
      }

      @include for-screen-up(1280) {
        left: -32rem;
        top: 7.8rem;
      }

      @include for-screen-up(1680) {
        left: -34.7rem;
        top: 5.5rem;
      }
    }

    &:nth-child(2) {
      transform: translateX(200%);
      transition-delay: 0.3s;

      @include for-screen-up(960) {
        left: -22.3rem;
        top: 33rem;
      }

      @include for-screen-up(1280) {
        top: 42.5rem;
        left: -27.8rem;
      }

      @include for-screen-up(1680) {
        left: -30.4rem;
        top: 46rem;
      }
    }

    &:nth-child(3) {
      transform: translateX(-200%);
      transition-delay: 0.6s;

      @include for-screen-up(960) {
        right: -22.8rem;
        top: 4.1rem;
      }

      @include for-screen-up(1280) {
        top: 4.1rem;
        right: -28.7rem;
      }

      @include for-screen-up(1680) {
        right: -31rem;
        top: 4.7rem;
      }
    }

    &:nth-child(4) {
      transform: translateX(-200%);
      transition-delay: 0.9s;

      @include for-screen-up(960) {
        right: -25.5rem;
        top: 28.4rem;
      }

      @include for-screen-up(1280) {
        top: 36rem;
        right: -32.1rem;
      }

      @include for-screen-up(1680) {
        right: -34.7rem;
        top: 38.8rem;
      }
    }

    #{$section}_animation-start & {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__list-heading {
    font-family: Lack, sans-serif;
    color: var(--color-secondary-105);
    font-size: 1.4rem;
    line-height: 1.36;

    @include for-screen-up(1280) {
      font-size: 1.7rem;
    }

    @include for-screen-up(1680) {
      font-size: 1.9rem;
    }
  }

  &__list-img {
    display: block;
    width: 100%;
    margin: auto;
    transform: translateY(100%);
    transition: transform 0.3s ease;

    #{$section}__list-item:nth-child(1) & {
      transition-delay: 1.3s;
    }

    #{$section}__list-item:nth-child(2) & {
      transition-delay: 1.6s;
    }

    #{$section}__list-item:nth-child(3) & {
      transition-delay: 1.9s;
    }

    #{$section}__list-item:nth-child(4) & {
      transition-delay: 2.2s;
    }

    #{$section}_animation-start & {
      transform: translateY(0);
    }
  }

  &__list-line {
    display: none;

    @include for-screen-up(960) {
      display: block;
      background: var(--color-secondary-104);
      position: absolute;
      height: 3.2rem;

      &:after,
      &:before {
        content: '';
        background: var(--bg-color-primary-white);
        height: 0.8rem;
        position: absolute;
        left: 0;
        right: 0;
      }

      &:before {
        border-radius: 0 0 1.8rem 1.8rem;
        top: 0;
      }

      &:after {
        border-radius: 1.8rem 1.8rem 0 0;
        bottom: 0;
      }
    }

    @include for-screen-up(1280) {
      height: 4rem;

      &:after,
      &:before {
        height: 1.1rem;
      }
    }

    #{$section}__list-item:nth-child(1) & {
      width: 7.1rem;
      bottom: 3.9rem;
      left: 100%;
      transition-delay: 1s;

      @include for-screen-up(1280) {
        width: 8.8rem;
      }

      @include for-screen-up(1680) {
        width: 9.7rem;
      }
    }

    #{$section}__list-item:nth-child(2) & {
      width: 4rem;
      bottom: 8.4rem;
      left: 100%;
      transition-delay: 1.3s;

      @include for-screen-up(1280) {
        width: 4.6rem;
      }

      @include for-screen-up(1680) {
        width: 5.4rem;
      }
    }

    #{$section}__list-item:nth-child(3) & {
      width: 4.5rem;
      bottom: 3.8rem;
      right: 100%;
      transition-delay: 1.6s;

      @include for-screen-up(1280) {
        width: 5.5rem;
      }

      @include for-screen-up(1680) {
        width: 6rem;
      }
    }

    #{$section}__list-item:nth-child(4) & {
      width: 7.2rem;
      bottom: 3.8rem;
      right: 100%;
      transition-delay: 1.9s;

      @include for-screen-up(1280) {
        width: 8.9rem;
      }

      @include for-screen-up(1680) {
        width: 9.7rem;
      }
    }
  }

  &__list-fig {
    border-radius: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 8.4rem;
    height: 8.4rem;
    overflow: hidden;
    position: relative;

    clip-path: inset(0 0 0 0 round 50%);

    @include for-screen-up(960) {
      width: 8.8rem;
      height: 8.8rem;
    }

    @include for-screen-up(1280) {
      width: 11.2rem;
      height: 11.2rem;
    }

    @include for-screen-up(1366) {
      width: 12rem;
      height: 12rem;
    }

    #{$section}__list-item:nth-child(1) & {
      background-color: #fed7ff;
    }

    #{$section}__list-item:nth-child(2) & {
      background-color: #cbffa1;
    }

    #{$section}__list-item:nth-child(3) & {
      background-color: #bbf3ff;
    }

    #{$section}__list-item:nth-child(4) & {
      background-color: #fff59b;
    }
  }
}
