@import '../../styles/media-queries';

.section {
  $section: &;
  position: relative;
  z-index: 3;

  &__inner {
    padding: 8.1rem 1.8rem 10.9rem;
    clip-path: inset(0 -2.1rem 0 -2.1rem round 8rem 0 0 0);

    @include for-screen-up(375) {
      clip-path: inset(0 -2.1rem 0 -2.1rem round 8rem 8rem 0 0);
    }

    @include for-screen-up(640) {
      clip-path: inset(0 -3rem 0 -3rem round 13.1rem 13.1rem 0 0);

      padding: 8.1rem 6rem 12.6rem;
    }

    @include for-screen-up(960) {
      padding-bottom: 17.2rem;
    }

    @include for-screen-up(1024) {
      clip-path: inset(0 -3rem 0 -3rem round 14rem 14rem 0 0);

      padding: 13rem 6.3rem 17.2rem;
    }

    @include for-screen-up(1366) {
      padding: 13rem 15rem 28.3rem;
    }
  }

  &__bg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -2.1rem;
      right: -2.1rem;
      bottom: 0;
      background: var(--color-secondary-93);
      clip-path: inset(0 0 0 0 round 8rem 0 0 0);

      @include for-screen-up(375) {
        clip-path: inset(0 0 0 0 round 8rem 8rem 0 0);
      }

      @include for-screen-up(640) {
        clip-path: inset(0 0 0 0 round 13.1rem 13.1rem 0 0);
        left: -3rem;
        right: -3rem;
      }

      @include for-screen-up(1024) {
        clip-path: inset(0 0 0 0 round 14rem 14rem 0 0);
      }
    }
  }

  &__content {
    max-width: 108rem;
    margin: auto;
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__heading {
    margin: 0 0 2.3rem;
    color: var(--text-color-primary-white);
    font-size: 3rem;

    line-height: 1.36;
    text-align: center;

    @include for-screen-up(360) {
      font-size: 3.7rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 5.2rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 8.1rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 8.1rem;
      font-size: 5.3rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 6rem;
      font-size: 6rem;
    }

    @include for-screen-up(1680) {
      font-size: 6.5rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 2rem;
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: list;
    position: relative;

    @include for-screen-up(960) {
      gap: 7.7rem;
    }
  }

  &__list-item {
    background: #2e2e2e;
    border-radius: 3rem;
    width: 100%;
    margin: 0;
    padding: 3.5rem 3.2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    @include for-screen-up(960) {
      background: none;
      flex-direction: row;
      padding: 0;
      position: sticky;

      top: 20%;
    }
  }

  &__list-heading {
    margin-bottom: 1rem;
    color: var(--text-color-primary-white);
    font-size: 2.5rem;
    font-weight: normal;
    line-height: 1.4;

    @include for-screen-up(960) {
      margin-bottom: 0.4rem;
      font-size: 3.6rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 0.4rem;
      font-size: 4rem;
    }

    &:before {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.9rem;
      content: counter(list);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.4rem;
      height: 3.4rem;
      color: var(--text-color-primary-white);
      font-family: Gilroy, sans-serif;
      font-size: 1.7rem;
      margin-bottom: 0.8rem;

      @include for-screen-up(960) {
        width: 4.4rem;
        height: 4.4rem;
        font-size: 2.2rem;
        margin-bottom: 2.1rem;
        border-radius: 1.2rem;
      }
    }
  }

  &__list-summary {
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    line-height: 1.7;

    @include for-screen-up(960) {
      font-size: 1.8rem;
    }
  }

  &__list-fig {
    margin: 0 0 2.2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    opacity: 0;

    transition: opacity 0.5s ease, transform 0.5s ease;

    #{$section}__list-item_active & {
      opacity: 1;
    }

    @include for-screen-up(960) {
      flex: 0 1 48.7rem;
      margin: 0;
    }

    @include for-screen-up(1024) {
      flex: 0 1 52rem;
      min-width: 52rem;
    }
  }

  &__list-fig-in {
    position: relative;
    width: 100%;
    padding-bottom: 80.77%;

    @include for-screen-up(960) {
      background: #2e2e2e;
      border-radius: 3.6rem;
    }

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__list-list {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    margin: 1.1rem 0 0;
    padding: 0;
    list-style: none;
  }

  &__list-list-item {
    margin: 0;
    padding: 0 0 0 2.2rem;
    color: var(--text-color-primary-white);

    font-family: Gilroy, sans-serif;
    font-size: 1.4rem;
    line-height: 1.7;
    position: relative;

    @include for-screen-up(960) {
      font-size: 1.6rem;
    }

    &:before {
      content: '';
      background: var(--bg-color-primary-white);
      width: 0.3rem;
      height: 0.3rem;
      position: absolute;
      top: 1rem;
      left: 0.8rem;
    }
  }

  &__list-content {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    counter-increment: list;
    box-sizing: border-box;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;

    #{$section}__list-item_active & {
      opacity: 1;
      //transform: translateY(0%);
    }

    @include for-screen-up(960) {
      flex: 0 1 calc(50% - 7.4rem);
      padding-left: 4rem;
    }

    @include for-screen-up(1024) {
      flex: 1 1 auto;
    }

    @include for-screen-up(1366) {
      padding-left: 7.4rem;
    }
  }
}
