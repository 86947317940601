@import '../../styles/media-queries';

.section {
  &__inner {
    max-width: 115rem;
    margin: auto;
  }

  &__heading {
    margin: 0 0 3.2rem;
    color: var(--text-color-primary-white);
    font-size: 3rem;

    line-height: 1.36;
    text-align: center;

    @include for-screen-up(360) {
      font-size: 3.6rem;
    }

    @include for-screen-up(640) {
      font-size: 4rem;
    }

    @include for-screen-up(960) {
      font-size: 5rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 4rem;
      font-size: 5.3rem;
    }

    @include for-screen-up(1920) {
      margin-bottom: 6rem;
      font-size: 6.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 3.2rem;

    @include for-screen-up(960) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__content-column {
    @include for-screen-up(960) {
      flex: 0 1 50%;
    }
  }

  &__content-scroll {
    background: var(--color-secondary-92);
    border-radius: 2.4rem;
    padding: 1.4rem 0.95rem 2.4rem;

    @include for-screen-up(1920) {
      padding: 1.2rem 1.2rem 4rem;
    }
  }

  &__content-scroll-inner {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    height: 37rem;
    padding: 2.8rem 1.2rem 0;

    @include for-screen-up(1920) {
      padding: 2.8rem 2.4rem 0;
    }

    &::-webkit-scrollbar {
      background: transparent;
      width: 0.4rem;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-stroke-2);
      border-radius: 0.3rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @include for-screen-up(640) {
      flex-direction: row;
      align-items: center;
    }

    @include for-screen-up(960) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include for-screen-up(1024) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__list-slider,
  &__list-content {
    @include for-screen-up(640) {
      flex: 0 1 50%;
      width: 50%;
    }
    @include for-screen-up(960) {
      flex: 1 1 auto;
      width: 100%;
    }
    @include for-screen-up(1024) {
      flex: 0 1 50%;
      width: 50%;
    }
  }

  &__list-heading {
    display: flex;
    margin-bottom: 0.85rem;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1.6rem;
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.7;
  }

  &__list-heading-icon {
    width: 3.6rem;
  }

  &__list-heading-icon-svg {
    display: block;
  }

  &__list-text1 {
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7;
  }

  &__list-text2 {
    margin-top: 0.4rem;

    color: var(--text-color-secondary);
    font-family: Gilroy, sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7;
  }

  &__plans {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    justify-content: center;
  }

  &__plans-turnover {
    margin-bottom: 1.2rem;
    color: var(--text-color-primary-white);
    font-size: 1.7rem;
    line-height: 1.36;
    font-weight: 400;

    @include for-screen-up(960) {
      font-size: 2.4rem;
    }
  }

  &__plans-total {
    margin-bottom: 3.2rem;
    color: var(--text-color-primary-white);
    font-size: 2.9rem;
    font-weight: 400;
    line-height: 1.4;

    @include for-screen-up(960) {
      font-size: 3.6rem;
    }

    @include for-screen-up(1920) {
      font-size: 4rem;
    }
  }

  &__plans-summary {
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.7;
  }

  &__min-value-error {
    background-color: #1b1e22;
    margin-bottom: 2.4rem;
    box-shadow: 0 0 0.4rem 0 rgba(24, 26, 31, 0.04),
      0 0 1.2rem 0 rgba(24, 26, 31, 0.12), 0 0 2rem 0 rgba(24, 26, 31, 0.2);
    border-radius: 1.6rem;
    padding: 1.2rem 1.2rem 1.2rem 0.8rem;
    display: flex;
    gap: 2rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: var(--text-color-primary-white);
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;

    svg {
      display: block;
    }

    &:before {
      content: '';
      background: #240300;
      width: 4rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}
