@import '../../styles/media-queries';

.section {
  $section: &;

  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(30%) scale(0.9);
  transition: transform 0.5s ease, opacity 0.5s ease;

  &_animation-start {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  @include for-screen-up(640) {
    padding: 0 6rem;
  }

  @include for-screen-up(960) {
    padding: 0 1rem;
  }

  @include for-screen-up(1024) {
    padding: 0 2.8rem;
  }

  @include for-screen-up(1680) {
    padding: 0 4rem;
  }

  &__inner {
    background: #fff9e3 url('/images/promocodes/bg.png') no-repeat 160% 0;
    background-size: auto 27.7rem;

    border-radius: 3.5rem;
    max-width: 160rem;
    margin: auto;

    @include for-screen-up(640) {
      background-position: 115% 0;
    }

    @include for-screen-up(960) {
      background-position: 115% 50%;
      background-size: auto 41.8rem;
    }

    @include for-screen-up(1024) {
      background-position: 110% 50%;

      background-size: auto 45.5rem;
    }

    @include for-screen-up(1366) {
      background-position: 110% 0;
      background-size: auto 57.5rem;
    }

    @include for-screen-up(1680) {
      background-position: 100% 0;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;
    align-items: flex-start;

    padding: 28.8rem 1.8rem 5rem;

    @include for-screen-up(640) {
      padding: 29.4rem 5.2rem 3.9rem;
    }

    @include for-screen-up(960) {
      padding: 7.8rem 0 7.7rem 7.6rem;
    }

    @include for-screen-up(1024) {
      padding: 7.8rem 0 7.7rem 7.6rem;
    }

    @include for-screen-up(1366) {
      padding: 10.7rem 0 10.7rem 11.1rem;
    }

    @include for-screen-up(1680) {
      padding: 12.9rem 0 12.9rem 14.6rem;
    }

    @include for-screen-up(1920) {
      max-width: 160rem;
      padding: 12.9rem 0 12.9rem 14.6rem;
    }
  }

  &__content-inner {
    @include for-screen-up(960) {
      width: 38.9rem;
    }

    @include for-screen-up(1366) {
      width: 53.1rem;
    }

    @include for-screen-up(1680) {
      width: 60rem;
    }
  }

  &__summary {
    color: var(--text-color-primary-black);
    margin: 0 auto 2.7rem;
    font-size: 1.7rem;
    white-space: pre-wrap;

    font-weight: normal;
    line-height: 1.7;

    @include for-screen-up(640) {
      margin-bottom: 2.6rem;
      font-size: 1.9rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 4rem;
      font-size: 2.6rem;
    }
  }

  &__button {
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__grain {
    position: absolute;
    /*right: 0;
    opacity: 0;
    transform: translateX(500%) scale(0.5);
    transition: transform 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8) 0.3s,
      right 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8) 0.3s,
      opacity 0.3s ease 0.3s;*/

    @include for-screen-up(640) {
      transform: /*translateX(500%)*/ scale(0.6);
    }

    @include for-screen-up(960) {
      transform: /*translateX(500%)*/ scale(0.8);
    }

    @include for-screen-up(1024) {
      transform: /*translateX(500%)*/ scale(1);
    }

    #{$section}_animation-start & {
      transform: /*translateX(0%)*/ scale(0.5);
      opacity: 1;

      @include for-screen-up(640) {
        transform:/* translateX(0%)*/ scale(0.6);
      }

      @include for-screen-up(960) {
        transform: /*translateX(0%)*/ scale(0.8);
      }

      @include for-screen-up(1024) {
        transform: /*translateX(0%)*/ scale(1);
      }
    }
  }

  &__dollar {
    background: url('/images/promocodes/dollar.svg') no-repeat;
    width: 9.8rem;
    height: 7.8rem;

    top: 3%;

    @include for-screen-up(960) {
      top: 11%;
    }

    #{$section}_animation-start & {
      right: 63%;

      @include for-screen-up(640) {
        right: 49%;
      }

      @include for-screen-up(960) {
        right: 28%;
      }

      @include for-screen-up(1024) {
        right: 32%;
      }

      @include for-screen-up(1280) {
        right: 30%;
      }

      @include for-screen-up(1680) {
        right: 25%;
      }
    }
  }

  &__grain1 {
    background: url('/images/promocodes/grain1.svg') no-repeat;
    width: 4.6rem;
    height: 4.3rem;
    position: absolute;
    top: 32%;

    @include for-screen-up(960) {
      top: 30%;
    }

    #{$section}_animation-start & {
      right: 75%;

      @include for-screen-up(640) {
        right: 55%;
      }

      @include for-screen-up(960) {
        right: 45%;
      }

      @include for-screen-up(1024) {
        right: 49%;
      }

      @include for-screen-up(1280) {
        right: 47%;
      }

      @include for-screen-up(1680) {
        right: 42%;
      }
    }
  }

  &__grain2 {
    background: url('/images/promocodes/grain2.svg') no-repeat;
    width: 4.7rem;
    height: 4.7rem;
    position: absolute;

    top: 14%;

    @include for-screen-up(960) {
      top: 54%;
    }

    #{$section}_animation-start & {
      right: 47%;

      @include for-screen-up(640) {
        right: 37%;
      }

      @include for-screen-up(960) {
        right: 45%;
      }

      @include for-screen-up(1024) {
        right: 49%;
      }

      @include for-screen-up(1280) {
        right: 47%;
      }

      @include for-screen-up(1680) {
        right: 42%;
      }
    }
  }

  &__grain3 {
    background: url('/images/promocodes/grain3.svg') no-repeat;
    width: 5.6rem;
    height: 5.3rem;
    position: absolute;
    top: 81%;

    display: none;

    @include for-screen-up(960) {
      display: block;
    }

    #{$section}_animation-start & {
      @include for-screen-up(960) {
        right: 31%;
      }

      @include for-screen-up(1024) {
        right: 35%;
      }

      @include for-screen-up(1280) {
        right: 33%;
      }

      @include for-screen-up(1680) {
        right: 28%;
      }
    }
  }
}
