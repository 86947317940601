$track-w: 100%;
$track-h: 0.2rem;
$thumb-d: 2.4rem;
$track-c: var(--interface-sixth);
$fill-c: var(--bg-color-primary-lightgrey);

@mixin thumb() {
  -webkit-appearance: none;
  appearance: none;
  background: var(--bg-color-primary-white);
  border: 0.15rem solid var(--bg-color-primary-blue);
  border-radius: 50%;
  width: $thumb-d;
  height: $thumb-d;
  box-sizing: border-box;
  cursor: grab;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

@mixin fill() {
  height: $track-h;
  background: $fill-c;
}

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: $track-w;
  height: $track-h;
  background: $track-c;
  border-radius: 0.4rem;

  @if $fill == 1 {
    background: linear-gradient($fill-c, $fill-c)
      0 /
      var(--sx)
      100%
      no-repeat
      $track-c;
  }
}

.slider {
  &__label {
    display: block;
    height: $thumb-d;
    position: relative;
  }

  &__range {
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--val) - var(--min)) / var(--range));
    --sx: calc(0.5 *#{$thumb-d} + var(--ratio) * (100% - #{$thumb-d}));
    -webkit-appearance: none;
    appearance: none;
    width: $track-w;
    height: $track-h;
    border-radius: 0.4rem;
    background: transparent;
    outline: none;

    &:active {
      &::-webkit-slider-thumb {
        cursor: grabbing;
        background-color: var(--bg-color-primary-white);
        border-color: var(--bg-color-primary-white);
      }

      &::-moz-range-thumb {
        cursor: grabbing;
        background-color: var(--bg-color-primary-white);
        border-color: var(--bg-color-primary-white);
      }
    }

    &::-webkit-slider-thumb {
      @include thumb;
      margin-top: 0.5 * ($track-h - $thumb-d);
    }

    &::-moz-range-thumb {
      @include thumb;
      margin-top: 0;
    }

    &::-webkit-slider-runnable-track {
      @include track(1);
    }

    &::-moz-range-track {
      @include track;
    }

    &::-moz-range-progress {
      @include fill;
    }
  }

  &__tooltip {
    background: var(--bg-color-primary-white);
    border-radius: 0.8rem;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.9rem 1.6rem;
    pointer-events: none;
    transform: translate(calc(-50% + 1.4rem), calc(-100% - 0.7rem));
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.375;
    text-align: center;

    &:before {
      content: '';
      border-style: solid;
      border-width: 1rem 1rem 0 1rem;
      border-color: var(--bg-color-primary-white) transparent transparent
        transparent;
      height: 0;
      width: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &_position-end {
      transform: translate(calc(-81% + 1.4rem), calc(-100% - 0.7rem));

      &:before {
        left: 81%;
      }
    }

    &_position-start {
      transform: translate(calc(-29% + 0.4rem), calc(-100% - 0.7rem));

      &:before {
        left: 41%;
      }
    }
  }

  &__footer {
    padding: 0.4rem 0 0 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Gilroy, sans-serif;
  }

  &__min {
    color: var(--interface-fifth);
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: 500;
  }

  &__max {
    color: var(--text-color-secondary);
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: 500;
  }
}
