@import '../../styles/media-queries';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &__content {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 8.2rem);
    align-items: stretch;

    @include for-screen-up(1024) {
      min-height: calc(100vh - 8.8rem);
    }

    @include for-screen-up(1024) {
      min-height: calc(100vh - 12rem);
    }
  }

  &__column {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-height: inherit;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &:first-child {
      padding: 7.7rem 1.8rem 0;
      opacity: 0;
      will-change: opacity;
      transition: opacity 1s ease 0.9s;

      #{$section}_animation-start & {
        opacity: 1;
      }

      @include for-screen-up(640) {
        padding: 7.7rem 9.69vw 0;
      }

      @include for-screen-up(960) {
        padding: 7.7rem 0 0;
        align-items: center;
        justify-content: center;

        flex: 0 1 auto;
        min-width: 50%;
        max-width: 50%;
      }

      @include for-screen-up(1024) {
        padding: 8.2rem 0 0;
      }

      @include for-screen-up(1366) {
        min-width: 46%;
        max-width: 46%;
      }

      @include for-screen-up(1680) {
        min-width: 48%;
        max-width: 48%;
      }

      & #{$section}__column-inner {
        width: 100%;

        @include for-screen-up(640) {
        }

        @include for-screen-up(960) {
          width: 37rem;
        }

        @include for-screen-up(1366) {
          width: 30.08vw;
        }

        @include for-screen-up(1680) {
          width: 27.14vw;
        }

        @include for-screen-up(1920) {
          width: 23.75vw;
        }

        @include for-screen-up(2560) {
          width: 22.26vw;
        }
      }
    }

    &:last-child {
      display: none;

      &:before {
        content: '';
        background: #e1f3ff;
        border-radius: 0 0 0 4rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        transform: translate3d(100%, 0, 0);
        will-change: transfrom;
        transition: transform 1s ease 0.6s;

        #{$section}_animation-start & {
          transform: translate3d(0%, 0, 0);
        }
      }

      @include for-screen-up(960) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 auto;
        min-width: 50%;
        max-width: 50%;
        //padding: 7.7rem 0 0;
      }

      @include for-screen-up(1024) {
        //padding: 8.2rem 0 0;
      }

      @include for-screen-up(1366) {
        min-width: 54%;
        max-width: 54%;
      }

      @include for-screen-up(1680) {
        min-width: 52%;
        max-width: 52%;
      }

      & #{$section}__column-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        will-change: opacity;
        transition: opacity 1s ease 1.1s;

        #{$section}_animation-start & {
          opacity: 1;
        }
      }
    }
  }

  &__auth-links {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include for-screen-up(640) {
      flex-direction: row;
    }
  }

  &__button-link {
    flex: 0 1 auto;
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 0 1rem;
    font-size: 3.2rem;
    position: relative;

    font-weight: normal;
    line-height: 1.3;
    display: table;

    @include for-screen-up(360) {
      font-size: 3.6rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 1.5rem;
      font-size: 4.5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.5rem;
      font-size: 3.6rem;
    }

    @include for-screen-up(1024) {
      font-size: 3.8rem;
    }

    @include for-screen-up(1366) {
      font-size: 4.5rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 1.5rem;
      font-size: 5rem;
    }

    @include for-screen-up(2560) {
      margin-bottom: 1.875rem;
      font-size: 5.6rem;
    }
  }

  &__heading-sticker {
    transform: rotate(0deg);
    margin-right: -2rem;
    clip-path: inset(0 50% 0 50%);

    transition: transform 0.3s ease 2.5s, clip-path 0.3s ease 2s;

    @include for-screen-up(640) {
      margin-left: 1rem;
    }

    @include for-screen-up(1366) {
      margin-right: -3rem;
    }

    @include for-screen-up(1680) {
      margin-right: -5rem;
    }

    #{$section}_animation-start & {
      transform: rotate(-3deg);
      clip-path: inset(0 0 0 0);
    }
  }

  &__summary {
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    margin-bottom: 1.8rem;
    font-size: 1.7rem;
    line-height: 1.6;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 1.5rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      font-size: 1.6rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 2.5rem;
      font-size: 1.6rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.7rem;
    }

    @include for-screen-up(1680) {
      font-size: 2.1rem;
    }
  }

  &__summary-list {
    margin: 0 0 3.5rem;
    padding: 0;
    list-style: none;

    @include for-screen-up(960) {
      margin-bottom: 4.7rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 5rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 3.5rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 7rem;
    }
  }

  &__summary-list-item {
    background: url('/images/emoji/fingerup/40.svg') no-repeat 0 0.7rem;
    background-size: 2.8rem auto;
    margin: 0 0 2.5rem;
    padding: 0 0 0 3.6rem;
    font-family: Gilroy, sans-serif;
    min-height: 4rem;
    font-size: 1.5rem;
    color: var(--text-color-primary-black);
    line-height: 1.7;

    @include for-screen-up(360) {
      font-size: 1.7rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
    }

    @include for-screen-up(1366) {
      font-size: 1.4rem;
    }

    @include for-screen-up(1680) {
      font-size: 1.8rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__benefits {
    width: 100%;
    height: 100%;
  }
}
