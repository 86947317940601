@import '../../styles/media-queries';

.section {
  $section: &;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-sizing: border-box;
  background: var(--bg-color-primary-white);
  clip-path: inset(0 -2.1rem 0 -2.1rem round 8rem 8rem 0 0);

  @include for-screen-up(640) {
    clip-path: inset(0 -3rem 0 -3rem round 13.1rem 13.1rem 0 0);
  }

  @include for-screen-up(1024) {
    clip-path: inset(0 -3rem 0 -3rem round 14rem 14rem 0 0);
  }

  &__inner {
    max-width: 160rem;
    margin: auto;
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: stretch;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;

    padding: 8.1rem 1.8rem 0;

    @include for-screen-up(640) {
      padding: 12.2rem 5.9rem 0;
    }

    @include for-screen-up(960) {
      padding: 12.2rem 1rem 0;
    }

    @include for-screen-up(1024) {
      padding: 9.6rem 1rem 0;
    }

    @include for-screen-up(1366) {
      padding: 9.6rem 2rem 0;
    }

    @include for-screen-up(1680) {
      padding: 13rem 0 0;
    }
  }

  &__heading {
    color: var(--text-color-primary-black);
    margin: 0 auto 3.1rem;
    font-size: 3.6rem;

    font-weight: normal;
    line-height: 1.36;
    text-align: center;

    @include for-screen-up(640) {
      margin-bottom: 4.5rem;
      font-size: 4.7rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 3rem;
      font-size: 4rem;
    }

    @include for-screen-up(1024) {
      margin-bottom: 3.9rem;
      font-size: 4rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 5rem;
      max-width: 101.1rem;
      font-size: 5.3rem;
    }

    @include for-screen-up(1680) {
      margin-bottom: 6.1rem;
      font-size: 6.5rem;
    }
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
    align-items: stretch;
    justify-content: stretch;

    @include for-screen-up(640) {
      gap: 2.5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 1.8rem;
      display: grid;
      min-height: 46.5rem;

      grid-template-columns: repeat(380, 1fr);
      grid-template-rows: repeat(380, 1fr);
      gap: 0;
    }

    @include for-screen-up(1024) {
      min-height: 49.4rem;

      margin-bottom: 1.8rem;
    }

    @include for-screen-up(1366) {
      margin-bottom: 2.6rem;
      min-height: 65rem;
    }

    @include for-screen-up(1680) {
      min-height: 79rem;
      margin-bottom: 3rem;
    }
  }

  &__list-item {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    opacity: 0;
    transition: opacity 1s ease;

    &:nth-child(1) {
      transition-delay: 0.3s;

      @include for-screen-up(960) {
        grid-area: 1 / 1 / 381 / 177;
      }
    }

    &:nth-child(2) {
      transition-delay: 1s;

      @include for-screen-up(960) {
        grid-area: 1 / 184 / 381 / 279;
      }
    }

    &:nth-child(3) {
      transition-delay: 2s;

      @include for-screen-up(960) {
        grid-area: 1 / 286 / 175 / 381;
      }

      @include for-screen-up(1024) {
        grid-area: 1 / 286 / 185 / 381;
      }

      @include for-screen-up(1366) {
        grid-area: 1 / 286 / 183 / 381;
      }
    }

    &:nth-child(4) {
      transition-delay: 3s;

      @include for-screen-up(960) {
        grid-area: 189 / 286 / 381 / 381;
      }

      @include for-screen-up(1024) {
        grid-area: 197 / 286 / 381 / 381;
      }

      @include for-screen-up(1366) {
        grid-area: 196 / 286 / 381 / 381;
      }
    }

    #{$section}_animation-start & {
      opacity: 1;
    }
  }

  &__list-card {
    background: #f6f6f6;
    border-radius: 3rem;
    padding: 4rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--color-secondary-30);
    }

    @include for-screen-up(640) {
      border-radius: 4rem;
    }

    @include for-screen-up(1680) {
      border-radius: 5rem;
    }

    #{$section}__list-item:nth-child(1) & {
      background-color: var(--color-secondary-80);

      &:hover {
        background-color: var(--color-secondary-82);
      }

      @include for-screen-up(640) {
        padding: 5.4rem 5rem 0.4rem;
      }

      @include for-screen-up(960) {
        padding: 5.4rem 5rem 5rem 5rem;
      }

      @include for-screen-up(1024) {
        padding: 5.9rem 5rem 5rem 5rem;
      }

      @include for-screen-up(1366) {
        padding: 8.2rem 4.8rem 5rem 6.5rem;
      }

      @include for-screen-up(1680) {
        padding: 11.2rem 8rem 8rem 8rem;
      }
    }

    #{$section}__list-item:nth-child(2) & {
      @include for-screen-up(640) {
        padding: 5.4rem 5rem 0.4rem;
      }

      @include for-screen-up(960) {
        padding: 3.7rem 2rem 2.7rem 2.7rem;
      }
      @include for-screen-up(1366) {
        padding: 4.9rem 2.7rem 2.7rem 3.6rem;
      }
      @include for-screen-up(1680) {
        padding: 5.9rem 4.5rem 4.5rem;
      }
    }

    #{$section}__list-item:nth-child(3) & {
      @include for-screen-up(640) {
        padding: 4.4rem 5rem 2.8rem;
      }

      @include for-screen-up(960) {
        padding: 3.4rem 1rem 0 2.8rem;
      }

      @include for-screen-up(1024) {
        padding: 3.2rem 2.8rem 0;
      }

      @include for-screen-up(1366) {
        padding: 4.2rem 3.8rem 0;
      }

      @include for-screen-up(1680) {
        padding: 5.4rem 4.5rem 0;
      }
    }

    #{$section}__list-item:nth-child(4) & {
      @include for-screen-up(640) {
        padding: 4rem 5rem 2.8rem;
      }

      @include for-screen-up(960) {
        padding: 2.9rem 2.4rem 0;
      }

      @include for-screen-up(1024) {
        padding: 4.3rem 2.9rem 0;
      }

      @include for-screen-up(1366) {
        padding: 5.7rem 3.8rem 0;
      }

      @include for-screen-up(1680) {
        padding: 6.9rem 4.5rem 0;
      }
    }
  }

  &__list-card-heading {
    margin-bottom: 1rem;
    line-height: 1.36;

    @include for-screen-up(640) {
      max-width: 32rem;
    }

    #{$section}__list-item:nth-child(1) & {
      font-size: 3.3rem;
      color: var(--text-color-primary-white);

      @include for-screen-up(640) {
        font-size: 4rem;
      }

      @include for-screen-up(1366) {
        margin-bottom: 0.9rem;
        max-width: none;
        font-size: 5.3rem;
      }

      @include for-screen-up(1680) {
        margin-bottom: 1rem;
        font-size: 6.5rem;
      }
    }

    #{$section}__list-item:nth-child(2) & {
      color: var(--text-color-primary-black);
      font-size: 3.3rem;

      @include for-screen-up(640) {
        font-size: 4rem;
      }

      @include for-screen-up(960) {
        font-size: 2.5rem;
      }

      @include for-screen-up(1280) {
        font-size: 2.9rem;
      }

      @include for-screen-up(1366) {
        font-size: 3.7rem;
      }

      @include for-screen-up(1680) {
        font-size: 4.3rem;
      }
    }

    #{$section}__list-item:nth-child(3) & {
      color: var(--text-color-primary-black);
      font-size: 3.3rem;

      @include for-screen-up(960) {
        margin-bottom: 0.5rem;
        font-size: 1.7rem;
      }

      @include for-screen-up(1024) {
        font-size: 2.1rem;
      }

      @include for-screen-up(1366) {
        font-size: 2.9rem;
      }

      @include for-screen-up(1680) {
        font-size: 3.6rem;
      }
    }

    #{$section}__list-item:nth-child(4) & {
      color: var(--text-color-primary-black);
      font-size: 3.3rem;

      @include for-screen-up(960) {
        margin-bottom: 0.5rem;
        font-size: 1.7rem;
      }

      @include for-screen-up(1024) {
        font-size: 2.1rem;
      }

      @include for-screen-up(1366) {
        font-size: 2.9rem;
      }

      @include for-screen-up(1680) {
        font-size: 3.6rem;
      }
    }
  }

  &__list-card-summary {
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;
    line-height: 1.7;

    #{$section}__list-item:nth-child(1) & {
      margin-bottom: 2.7rem;
      color: var(--text-color-primary-white);

      @include for-screen-up(640) {
        margin-bottom: 3.5rem;
        font-size: 1.5rem;
      }

      @include for-screen-up(1024) {
        margin-bottom: 2.7rem;

        font-size: 1.5rem;
      }

      @include for-screen-up(1366) {
        margin-bottom: 3.8rem;
        font-size: 1.9rem;
      }

      @include for-screen-up(1680) {
        margin-bottom: 3.1rem;

        font-size: 2.3rem;
      }
    }

    #{$section}__list-item:nth-child(2) & {
      margin-bottom: 3.7rem;
      color: var(--color-secondary-93);

      @include for-screen-up(640) {
        margin-bottom: 4.2rem;
        font-size: 1.5rem;
      }

      @include for-screen-up(960) {
        margin-bottom: 2rem;
        font-size: 1.3rem;
      }

      @include for-screen-up(1366) {
        margin-bottom: 5.1rem;

        font-size: 1.7rem;
      }

      @include for-screen-up(1680) {
        margin-bottom: 6.3rem;

        font-size: 2.1rem;
      }
    }

    #{$section}__list-item:nth-child(4) &,
    #{$section}__list-item:nth-child(3) & {
      color: var(--color-secondary-93);

      @include for-screen-up(640) {
        font-size: 1.6rem;
      }

      @include for-screen-up(960) {
        font-size: 1.3rem;
      }

      @include for-screen-up(1366) {
        font-size: 1.7rem;
      }

      @include for-screen-up(1680) {
        font-size: 2rem;
      }
    }
  }

  &__list-card-fig {
    #{$section}__list-item:nth-child(1) & {
      margin: 0 -6rem 0 -0.8rem;

      @include for-screen-up(640) {
        margin: 0 5.5rem 0 -1.6rem;
      }

      @include for-screen-up(960) {
        margin: 0 -4.7rem -5rem 0;
      }

      @include for-screen-up(1024) {
        margin: 0 -1.2rem -5rem 0;
      }

      @include for-screen-up(1680) {
        margin: 0 0 -8rem;
      }
    }

    #{$section}__list-item:nth-child(2) & {
      display: flex;
      justify-content: center;
      margin: 0 -4rem 0 -4rem;

      @include for-screen-up(640) {
        margin: 0 -5rem 0 -5rem;
      }

      @include for-screen-up(960) {
        margin: 0 -2rem -2.7rem -2.7rem;
      }

      @include for-screen-up(1024) {
        margin: 0 -2rem -2.7rem -2.7rem;
      }

      @include for-screen-up(1366) {
        margin: 0 -2.7rem -2.7rem -3.6rem;
      }

      @include for-screen-up(1680) {
        margin: 0 -4.5rem -4.5rem -4.5rem;
      }
    }

    #{$section}__list-item:nth-child(3) & {
      order: -1;
      margin: 0 0 1rem -0.5rem;

      width: 54%;

      @include for-screen-up(640) {
        margin: 0 0 1rem -1.5rem;
        width: 34%;
      }

      @include for-screen-up(960) {
        margin: 0 0 0.5rem;
        width: 54%;
      }

      @include for-screen-up(1024) {
        margin-bottom: 1.4rem;
        width: 54%;
      }
    }

    #{$section}__list-item:nth-child(4) & {
      order: -1;

      @include for-screen-up(640) {
        width: 62%;
        margin: 0 -2rem 0.5rem -1.1rem;
      }

      @include for-screen-up(960) {
        width: auto;
        margin: 0 -2rem 2.5rem 0;
      }

      @include for-screen-up(1024) {
        margin: 0 -0.4rem 2.5rem -0.6rem;
      }

      @include for-screen-up(1366) {
        margin: 0 -1rem 3.3rem -0.8rem;
      }

      @include for-screen-up(1680) {
        margin: 0 -2.3rem 4rem -0.8rem;
      }
    }
  }

  &__notice {
    color: var(--text-color-secondary);
    font-family: Gilroy, sans-serif;
    font-size: 1.2rem;
    line-height: 1.7;
    text-align: center;

    @include for-screen-up(1366) {
      font-size: 1.4rem;
    }

    @include for-screen-up(1680) {
      font-size: 1.7rem;
    }

    a {
      color: var(--text-color-primary-blue);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.lottie {
  svg {
    display: block;
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }
}
